import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import Image from 'gatsby-image'
import {Link} from 'gatsby'
import RecetaItem from '../components/RecetaItem'
import Button2 from '../components/Button2'
import timer from '../images/icon-timer.png'
import Hero from '../components/Hero'

export default ({ data }) => {
    const {allContentfulReceta:{nodes:recetas}}=data
    console.log(recetas)
    return(
        <Layout>
            <Hero titulo="Recetas 30 minutos o menos" subtitulo="Comida rica para gente floja"/>
            <section >
                {/* <BackgroundImg fluid={receta.fotoDePortada.fluid}/> */}
                <h1 className="text-cheflojo text-5xl pb-24 pt-24 text-center">Recetas</h1>
                <div className="pb-24 grid grid-cols-1 lg:grid-cols-4 px-12 space-x-4 md:w-2/3 m-auto ">
                {recetas.map(receta => {
                    return(
                        <Link className="text-cheflojo m-auto" to={`/recetas/${receta.slug}`}>
                        <article className="transition border-white shadow-lg border duration-200 hover:bg-gray-100 rounded-lg mt-4 mb-4
                        hover:border-2 hover:border-gray-400 hover:shadow-xl" 
                        >
                            <Image className="rounded-lg shadow-lg mb-4" fluid={receta.fotoDePortada.fluid} height="20%" width="100px" alt="imagen de producto"/>
                                <RecetaItem className="">{receta.nombre}</RecetaItem>
                                <div className="flex justify-between items-center">
                                  <Link className="py-4 px-2" to={`/recetas/${receta.slug}`}><Button2>Ver</Button2></Link>
                                  <div className="flex items-center space-x-2 px-2">
                                    <p className="text-xs text-cheflojo">Preparación: {receta.tiempoDePreparacion} mins</p>
                                    <img src={timer} width="20px" alt="icon timer"/>
                                  </div>
                                </div>
                        </article>
                        </Link>
                    )
                })}
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
  {
    allContentfulReceta {
      nodes {
        nombre
        id
        slug
        preparacion {
          preparacion
        }
        ingredientes {
          ingredientes
        }
        fotoDePortada {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        tiempoDePreparacion
      }
    }
  }
`


